import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { graphql } from 'gatsby'
import { Layout, SEO, HeroHeading, Section, BeforeAfter } from '@components'
import { Page } from '@types'
import { Container } from '@bootstrap-styled/v4'
import { useResults } from '@hooks'

const AboutMePage: React.FC<Page<unknown>> = ({ data, location }) => {
  const { title, content } = data.wpgraphql.page
  const { transitionDurations } = useContext(ThemeContext)
  const workResults = useResults()

  return (
    <Layout>
      <SEO title={title} pageUrl={location.pathname} />
      <Section hasBg verticalPadding>
        <Container>
          <div>
            <HeroHeading
              className="mb-5"
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            >
              {title}
            </HeroHeading>
          </div>
          {content && (
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="clearfix"
              data-sal="slide-up"
              data-sal-duration={transitionDurations.sal}
            />
          )}
          {workResults.map((result, idx) => (
            <BeforeAfter
              key={idx}
              title={result.title}
              content={result.content}
              urlBefore={result.imageBefore.sourceUrl}
              urlAfter={result.imageAfter.sourceUrl}
            />
          ))}
        </Container>
      </Section>
    </Layout>
  )
}

export default AboutMePage

export const pageQuery = graphql`
  query {
    wpgraphql {
      page(id: "188", idType: DATABASE_ID) {
        slug
        title(format: RENDERED)
        uri
        databaseId
        isFrontPage
        content(format: RENDERED)
      }
    }
  }
`
